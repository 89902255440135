















import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import { CropStore, LoadStore } from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import aboveFooter from "@/components/UIComponents/aboveFooter.vue";

@Component({
  components: {
    Button,
    Homeslider,
    Cropstat,
    Recentvarieties,
    aboveFooter
  },
  metaInfo: {
    title: "Seed quality control center",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "keyword", content: "home,page" },
      {
        name: "description",
        content: "Home Page of SQCC App",
        vmid: "homeScreen"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class Home extends Vue {
  loading: boolean = true;

  private get crops() {
    return CropStore.CropNameList;
  }

  private get cropslide() {
    let crops = CropStore.Crops;
    let cropList: any[] = [];
    if (crops.length > 0) {
      for (let crop of crops) {
        cropList.push({
          id: crop.Id,
          title: crop.Name,
          content: crop.NumVarieties + " " + String(this.$tc("variety", crop.NumVarieties)),
          image: crop.Image,
          url: this.$router.resolve({
            name: "ListingScreen",
            params: { cropSlug: crop.Slug }
          }).href
        });
      }
    }
    return cropList;
  }

  private async created() {
    this.loading = true;
    await CropStore.getAllCrops({ lang: this.$i18n.locale });
    this.loading = false;
  }
}
