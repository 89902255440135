





















import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Action } from "@/store/actionType";
// import gapi from "https://apis.google.com/js/platform.js?onload=init";
@Component({
  components: {}
})
export default class FacebookLogin extends Vue {
  baseUi = Action.Ui;
  actionBase = axios.create({
    baseURL: this.baseUi
  });
  private authWindow: Window | null = null;
  private failed: boolean = false;
  private error: string = "";
  private errorDescription: string = "";
  private isBusy: boolean = false;
  private errors: string = "";
  private created() {
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessage.bind(this), false);
    } else {
      (window as any).attachEvent("onmessage", this.handleMessage.bind(this));
    }
  }

  // googleauth() {
  //   gapi.load("auth2", () => {
  //     gapi.auth2.authorize(
  //       {
  //         client_id:
  //           "152700106997-taoahga797f2k8bak651pdr2g12ie1jd.apps.googleusercontent.com",
  //         scope: "email profile openid",
  //         response_type: "id_token permission"
  //       },
  //       function(response) {
  //         if (response.error) {
  //           // An error happened!
  //           return;
  //         }
  //         // The user authorized the application for the scopes requested.
  //         var accessToken = response.access_token;
  //         var idToken = response.id_token;
  //         // You can also now use gapi.client to perform authenticated requests.
  //         var message = { oauth: "google" };
  //         if (idToken) {
  //           message.status = true;
  //           message.id_token = idToken;
  //         } else {
  //           message.status = false;
  //           message.error = "not logged in";
  //           message.errorDescription = "not logged in";
  //         }
  //         window.opener.postMessage(
  //           JSON.stringify(message),
  //           "http://localhost:8081"
  //         );
  //       }
  //     );
  //   });
  // }

  private launchGoogleLogin() {
    this.authWindow = window.open(this.baseUi + "/google-auth.html", "", "width=600,height=400");
    // this.actionBase.get("/google-auth.html");
  }
  private handleMessage(event: Event) {
    const message = event as MessageEvent;
    // Only trust messages from the below origin.
    if (message.origin !== this.baseUi) {
      return;
    }
    if (this.authWindow) {
      this.authWindow.close();
    }
    const result = JSON.parse(message.data);
    if (!result.status) {
      this.failed = true;
      this.error = result.error;
      this.errorDescription = result.errorDescription;
    } else if (result.oauth === "google") {
      this.failed = false;
      this.isBusy = true;
      this.$emit("google", { backend: "google", token: result.id_token });
    }
  }
}
