





























































































































































































































































import { Button } from "element-ui";
import Appfooter from "@/components/UIComponents/Appfooter.vue";
import { Vue, Component } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import LoginModal from "@/components/UIComponents/LoginModal.vue";
import DevelopersCredit from "@/components/UIComponents/DeveloperCredits.vue";
import {
  JWTStore,
  UserStore,
  LoadStore,
  CropVarietyStore,
  CompareCropStore,
  MetaStore,
  CropStore,
  CommentStore
} from "@/store/modules";
import { constants } from "crypto";

@Component({
  components: { LoginModal, Appfooter, DevelopersCredit }
})
export default class Home extends Vue {
  // isLoggedIn: boolean = JWTStore.loggedIn;
  // isSQCC: boolean = JWTStore.sqcc;
  mainIndex: number = 4;
  getItemIndex: number = 3;
  activeIndex1: string = "";
  activeIndex2: string = "2";
  activeIndex3: string = "3";
  activeIndex4: string = "4";
  activeIndex5: string = "5";
  activeIndex6: string = "6";
  isNepali: boolean = false;

  data() {
    return {
      seemenu: false,
      activeName: ""
    };
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get unseenStatus() {
    return CommentStore.UnseenStatus;
  }

  reloader() {
    this.reload = true;
    this.$nextTick(() => {
      this.reload = false;
      this.getAPIData();
    });
  }

  get reload() {
    return MetaStore.reload;
  }

  set reload(value: boolean) {
    MetaStore.setReload(value);
  }

  registerScreen() {
    this.$router
      .push({
        name: "Registersteps",
        params: { lang: this.$i18n.locale }
      })
      .catch(error => {});
  }

  getItems(categ: any, i: number) {
    let index = this.getItemIndex + i;
    if (categ["Meta"] !== undefined) {
      return Object.keys(categ["Meta"])[index];
    }
  }

  getData(categ: any, data: string) {
    if (categ["Meta"] !== undefined) {
      if (categ["Meta"][data].length > 0) {
        return categ["Meta"][data];
      }
    }
  }

  checkLength(categ: any, data: string) {
    if (categ["Meta"] !== undefined) {
      if (categ["Meta"][data].length === 0) {
        return 0;
      } else {
        return categ["Meta"][data].length;
      }
    }
  }

  getKeys(categ: any) {
    if (categ["Meta"] !== undefined) {
      // return Object.keys(categ["Meta"]).slice(
      //   this.getItemIndex,
      //   Object.keys(categ["Meta"]).length
      // );
      let keys = Object.keys(categ["Meta"]).filter(x => typeof categ["Meta"][x] !== "string");
      return keys;
    }
  }

  getSubIndex(index: number, i: number) {
    return String(index) + "-" + String(i + 1);
  }

  getIndex(index: number) {
    if (index !== undefined || index !== null) {
      return String(this.mainIndex + index);
    }
  }

  getMenuIndex(index: number) {
    if (index !== undefined || index !== null) {
      return String(index + 1);
    }
  }

  getMenuItems(categ: any) {
    if (categ["Meta"] !== undefined) {
      let keys = Object.keys(categ["Meta"]).filter(x => typeof categ["Meta"][x] === "string");
      this.getItemIndex = keys.length;
      this.mainIndex = keys.length + 1;
      return keys;
    }
  }

  get isLoggedIn() {
    return JWTStore.isAuthenticated;
  }

  get isSQCC() {
    return JWTStore.role === "SQCCAdmin" || JWTStore.role === "SQCCChief" || null;
  }

  get isSeedCompany() {
    return JWTStore.role === "supplier" || JWTStore.role === "consumer" || null;
  }

  get language() {
    return this.$i18n.locale;
  }

  get cropCategoryNavBar() {
    return CropStore.CropCategories;
  }

  handleSelect(key: any, keyPath: any) {
    // console.log(key, keyPath);
  }

  emitGloballogin() {
    eventHub.$emit("loginClick");
  }

  set language(lang: any) {
    this.$i18n.locale = lang;
    this.$moment.locale(this.$i18n.locale);
    let params = this.$route.params;
    params.lang = lang;

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: this.$route.query,
          params: this.$route.params
        })
        .catch(error => {});
    }
    this.reloader();
  }

  switchLanguage() {
    // this.$router.push({ name: "Home", params: { lang: this.$i18n.locale } }).catch(error => {});
  }

  get username() {
    // if (UserStore.User.username != "") {
    //   // console.log(UserStore.User);
    //   return UserStore.User.username;
    // }
    return UserStore.User.name;
  }

  get userProfilePicture() {
    return UserStore.User.picture;
  }

  logout() {
    JWTStore.logOut();
  }

  screenResize() {
    /**
     * This function checks if screen less than 576px (mobile screen)
     * And then set MetaStore's mobileScreen value
     */
    if (document.body.clientWidth <= 576) {
      MetaStore.setMobileScreen(true);
    } else {
      MetaStore.setMobileScreen(false);
    }
  }

  async getAPIData() {
    if (localStorage.getItem("access_token")) {
      await UserStore.getUser();
      if (this.isLoggedIn && this.isSQCC) {
        let commentUnseenStatusInterval = setInterval(CommentStore.getCommentUnseenCount, 1800000);
      }
    }
    await CropStore.getCropCategory({ lang: this.$i18n.locale });
    LoadStore.hideLoading();
    await CropStore.getAllCropName();

    await CropVarietyStore.getAllCropVariety();
    CommentStore.getCommentUnseenCount();
  }

  created() {
    /**
     * This is done to check if screen less than 576px (mobile screen)
     * And then set MetaStore's mobileScreen which can be used in vue components
     * like dialog box and made into fullscreen
     */
    window.addEventListener("resize", this.screenResize);
    this.screenResize();

    /** Set moment's locale */
    this.$moment.locale(this.$i18n.locale);

    eventHub.$on("reloadPage", this.reloader);
    this.getAPIData();
  }

  async cropTypeClick(cropSlug: string, cropName: string) {
    let cropId = await MetaStore.getCropIdBySlug(cropSlug);
    CropVarietyStore.saveFilterQuery({});
    CropVarietyStore.setSorter(null);
    CropVarietyStore.setPageNumber(1);
    CropVarietyStore.setCropIdInList(cropId);
    CompareCropStore.clearSelectedCrops();
    this.$router
      .push({
        name: "ListingScreen",
        params: {
          cropSlug: String(cropSlug)
        }
      })
      .catch(error => {});
    CropStore.getCropById({ id: cropId, lang: this.$i18n.locale });
    CropVarietyStore.getCropVarietyFilterables({
      cropId: cropId,
      lang: this.$i18n.locale
    });
    CropVarietyStore.getSortableVariables({
      cropId: cropId,
      lang: this.$i18n.locale
    });
    LoadStore.showLoadingCropVarieties();
    await CropVarietyStore.getAllCropVarietiesDetail({
      cropid: String(cropId),
      lang: this.$i18n.locale
    });
    LoadStore.hideLoadingCropVarieties();
  }
}
