<template>
  <div class="slider" ref="slider">
    <vueper-slides
      class="no-shadow"
      :visible-slides="4"
      :arrows="true"
      :slide-ratio="1 / 8"
      :dragging-distance="5"
      :breakpoints="{
        630: { visibleSlides: 2 },
        450: { visibleSlides: 1 },
        850: { visibleSlides: 3 }
      }"
      ref="draggableRoot"
    >
      <vueper-slide
        v-for="item in slides"
        :key="item.index"
        @pointerdown.native="handleDown"
        @pointerup.native="handleUp"
        @pointercancel.native="handleUp"
        @click.native="goto(item.url)"
        :title="item.title"
        :content="item.content"
        :image="item.image"
      ></vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import CropStore from "@/store/modules/crop";

export default {
  components: { VueperSlides, VueperSlide },
  props: {
    slides: Array
  },
  data() {
    return {
      noOfSlides: 3,
      hover: false,
      draggable: true,
      drag: false,
      x: 100,
      y: 100,
      left: 0,
      top: 0
    };
  },
  methods: {
    goto(url) {
      if (!this.drag) {
        this.$router.push(url).catch(error => {});
      }
    },
    handleMove({ pageX, pageY, clientX, clientY }) {
      if (this.$refs.draggableRoot) {
        this.x = pageX + this.left;
        this.y = pageY + this.top;
        this.drag = true;
      }
    },
    handleDown(event) {
      const { pageX, pageY } = event;
      const { left, top } = this.$refs.draggableRoot.$el.getBoundingClientRect();
      this.left = left - pageX;
      this.top = top - pageY;
      document.addEventListener("pointermove", this.handleMove);
    },
    handleUp() {
      document.removeEventListener("pointermove", this.handleMove);
      setTimeout(() => (this.drag = false));
    }
  },
  created() {},
  mounted() {
    var width = this.$refs.slider.clientWidth;
    this.noOfSlides = parseInt(width / 200);
  }
};
</script>

<style lang="sass" scoped>
.vueperslides__track-inner
  .vueperslide
    border-left: 6px solid #ffffff
    border-right: 6px solid #ffffff
.vueperslide__title
  margin-top: 120px !important
  color: #666

.vueperslide
  cursor: pointer
  transition: all .5s
  &:hover
    transform: scale(1.1)

.vueperslides__track
  &:before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    &:hover
      background-color: none
</style>
