
































import { Component, Vue } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";

@Component
export default class extends Vue {
  developerValue: number = 0;
  developerTime: number = 0;
  developerMenuVisible: boolean = false;

  developerPage() {
    this.developerTime++;
    this.developerValue++;
    if (this.developerValue >= 10) {
      this.developerMenuVisible = true;
      this.closeDeveloperCredit();
    }
    this.callFunction();
  }

  async callFunction() {
    await this.sleep(5000);
    this.developerTime--;
    if (this.developerTime <= 0) {
      this.developerValue = 0;
    }
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async closeDeveloperCredit() {
    await this.sleep(5000);
    this.developerMenuVisible = false;
  }

  created() {
    eventHub.$on("developersCredit", this.developerPage);
  }
}
