








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import { Action } from "@/store/actionType";
import { baseURL } from "@/store/API/api";

@Component
export default class Appfooter extends Vue {
  developersCredit() {
    eventHub.$emit("developersCredit");
  }

  get API() {
    return baseURL;
  }
}
